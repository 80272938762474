// import Image from "next/image";
import { setCookie } from "cookies-next";
// next
import { NextPage } from "next";
// import showToastMessages from "@/helpers/showToastMessages";
import Head from "next/head";
import { useRouter } from "next/router";

import ClientLogin from "@/views/auth/ClientLogin";
import {
  RhButton,
  RhIcon,
  RhInput,
  RhLoader,
  RhOTPInput,
  RhToast,
  // RhLoader,
} from "@rhythm-ui/react";
// assets

import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import { PhoneType } from "@/types/utils";

import api from "@/services/api";

import { COOKIE, LOCAL_STORAGE, USER_ROLE } from "@/constants";
import mixPanelEventName from "@/constants/mixPanelEventName";

import useAuth from "@/hooks/useAuth";
import useFocusInputField from "@/hooks/useFocusInputField";

import AnalyticsLog from "@/helpers/AnalyticsLog";
import getDomain from "@/helpers/getDomain";
import getFirstPermittedRoute from "@/helpers/getFirstPermittedRoute";
import showToastMessages from "@/helpers/showToastMessages";

import PhoneFormik from "@/components/formikInput/PhoneFormik";
import EntryLayout from "@/components/layout/EntryLayout";

const OTP_LENGTH = 6;
const RESEND_OTP_DURATION = 60; // in sec

const userSignInType = [
  {
    title: "I’m a Candidate",
    avatar: "/icons/candidateAvatar.svg",
    userRole: USER_ROLE.CANDIDATE,
  },
  {
    title: "I’m an Employer",
    avatar: "/icons/employerAvatar.svg",
    userRole: USER_ROLE.CLIENT,
  },
  {
    title: "I’m an Interviewer",
    avatar: "/icons/interviewerAvatar.svg",
    userRole: USER_ROLE.INTERVIEWER,
  },
];

const Login: NextPage = () => {
  return (
    <>
      <Head>
        <title>Login | Zelevate</title>
      </Head>
      <EntryLayout
        formPanel={<LoginForm />}
        greetingTitle={
          "Elevate Your Hiring Game with Pre-Interviewed Candidates"
        }
        greetingSubTitle={`Our Pre-Interviewed candidates have already gone through a
    rigorous evaluation process, so you can be confident that you
    are getting the best of the best. With Zelevate, you can
    streamline your hiring process and focus on what really matters
    – growing your business`}
      />
    </>
  );
};

const LoginForm = () => {
  const [phone, setPhone] = useState({ dialCode: "+91", phoneNumber: "" });
  const [otp, setOtp] = useState("");
  const [isPhoneEntered, setIsPhoneEntered] = useState(false);
  const [otpRequestSent, setOtpRequestSent] = useState(false);
  const [isVerifySent, setIsVerifySent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(3);
  const timerRef = useRef<ReturnType<typeof setInterval>>();
  const router = useRouter();
  const [redirectTo, setRedirectTo] = useState<string>("");

  const [isRequestingOtp, setIsRequestingOtp] = useState(false);

  useEffect(() => {
    if (router.isReady) {
      if (router.query?.redirect) {
        //try to validate ( not required)

        setRedirectTo(router.query?.redirect as string);
      }
    }
  }, [router]);

  const handlePhoneSubmit = (formData: { mobile__form_value: PhoneType }) => {
    sendOtp({
      dialCode: formData.mobile__form_value.dialCode,
      phoneNumber: formData.mobile__form_value.phoneNumber,
    });
    setPhone({
      dialCode: formData.mobile__form_value.dialCode,
      phoneNumber: formData.mobile__form_value.phoneNumber,
    });
  };

  const sendOtp = async (phone: PhoneType) => {
    setIsRequestingOtp(true);
    const payload = {
      mobile: `${phone.dialCode}${phone.phoneNumber}`,
      selected_role: selectedSignInUserType,
    };
    setOtpRequestSent(true);
    try {
      await api.auth.getOTP(payload);
      startResendTimer();
      setIsPhoneEntered(true);
      RhToast.success("OTP sent", {
        position: "bottom-right",
      });
      setIsPhoneEntered(true);
    } catch (error: any) {
      showToastMessages(error);
    }
    setIsRequestingOtp(false);
    setOtpRequestSent(false);
  };

  useEffect(() => {
    if (otp.length === OTP_LENGTH) {
      verifyOtp(phone, otp);
    }
  }, [otp]);

  const { setUserAuth } = useAuth();

  const verifyOtp = async (phone: PhoneType, otp: string) => {
    const payload = {
      mobile: `${phone.dialCode}${phone.phoneNumber}`,
      otp,
      selected_role: `${selectedSignInUserType}`,
    };
    setIsVerifySent(true);
    try {
      const domain = getDomain();
      // verifying OTP
      const res = await api.auth.verifyOTP(payload);
      setCookie(COOKIE.ZELACCESS, res.data.token.access, {
        domain,
        path: "/",
        sameSite: "strict",
      });
      setCookie(COOKIE.ZELREFRESH, res.data.token.refresh, {
        domain,
        path: "/",
        sameSite: "strict",
      });
      setCookie(COOKIE.ROLES, res.data.token.role.join("|"), {
        domain,
        path: "/",
        sameSite: "strict",
      });

      setUserAuth();

      const defaultRouteForLoggedInRole = getFirstPermittedRoute(
        res.data.token.role,
        selectedSignInUserType
      );
      // if redirect to is exist and validated  then redirect to the redirect url otherwise
      //  else redirect to defaultRouteForLoggedInRole
      redirectTo
        ? router.push(redirectTo + "?verified=true")
        : router.push(defaultRouteForLoggedInRole);
      AnalyticsLog.track(mixPanelEventName.LOGIN);
    } catch (error) {
      setOtp("");
      showToastMessages(error);
    }
    setIsVerifySent(false);
  };

  const handleEditPhone = () => {
    setIsPhoneEntered(false);
    if (timerRef.current) clearInterval(timerRef.current);
    setTimeLeft(RESEND_OTP_DURATION);
  };

  const handleResendOtp = () => {
    try {
      sendOtp(phone);
    } catch (error) {
      showToastMessages(error);
    }
  };

  const startResendTimer = () => {
    setTimeLeft(RESEND_OTP_DURATION);
    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setTimeLeft((state) => state - 1);
    }, 1000);
  };

  useEffect(() => {
    if (timeLeft === 0) stopResendTimer();
  }, [timeLeft]);

  const stopResendTimer = () => {
    clearInterval(timerRef.current);
    setTimeLeft(0);
  };

  const [selectedSignInUserType, setSelectedSignInUserType] = useState(
    USER_ROLE.CANDIDATE
  );

  const handleSignInUserTypeChange = (userRole: string) => {
    if (isRequestingOtp || isPhoneEntered) return;
    setSelectedSignInUserType(userRole);

    // save the user login type
    localStorage.setItem(LOCAL_STORAGE.LAST_LOGIN_USER_TYPE, userRole);
  };

  useEffect(() => {
    const lastLoginUserType =
      localStorage.getItem(LOCAL_STORAGE.LAST_LOGIN_USER_TYPE) ||
      USER_ROLE.CANDIDATE;
    setSelectedSignInUserType(lastLoginUserType);
  }, []);

  return (
    <>
      <h1>Sign In</h1>

      <div
        className={`overflow-hidden ${
          !isPhoneEntered ? "max-h-[500px]" : "max-h-0"
        }`}
        style={{ transition: "max-height 1s" }}
      >
        <p className="mt-tnano text-hint">Select</p>

        <div className="flex gap-tsm mt-tm2">
          {userSignInType.map((userType) => (
            <button
              key={userType.userRole}
              className={`h-[148px] w-[165px] relative border rounded-[6px] p-tm2 flex flex-col items-center justify-between cursor-pointer ${
                selectedSignInUserType === userType.userRole
                  ? "border-primary-500 bg-primary-50"
                  : ""
              }`}
              data-testid="persona-cards"
              onClick={() => handleSignInUserTypeChange(userType.userRole)}
            >
              <img src={userType.avatar} className=" w-[64px] h-[64px] " />
              <p className="text-sm">{userType.title}</p>
              <RhInput
                tabIndex={-1}
                type="radio"
                data-testid="persona-cards-radio-btn"
                className="absolute right-0 top-0 m-tsm"
                checked={selectedSignInUserType === userType.userRole}
              />
            </button>
          ))}
        </div>
      </div>
      <div className="mt-t2xl" />
      <h2 className="mb-tsm" data-testid="signing-in-as-header">
        Signing in as a {selectedSignInUserType}
      </h2>

      {(selectedSignInUserType === USER_ROLE.INTERVIEWER ||
        selectedSignInUserType === USER_ROLE.CANDIDATE) && (
        <>
          {!isPhoneEntered ? (
            <PhoneForm
              phone={phone}
              handlePhoneSubmit={handlePhoneSubmit}
              otpRequestSent={otpRequestSent}
            />
          ) : (
            <OtpForm
              phone={phone}
              handleEditPhone={handleEditPhone}
              otp={otp}
              setOtp={setOtp}
              timeLeft={timeLeft}
              handleResendOtp={handleResendOtp}
              isVerifySent={isVerifySent}
              isRequestingOtp={isRequestingOtp}
            />
          )}

          {!isPhoneEntered &&
            selectedSignInUserType === USER_ROLE.CANDIDATE && (
              <div className="flex justify-between flex-col">
                <p className="text-center mt-t2xl">
                  Want to register yourself as a job seeker?
                </p>
                <RhButton
                  layout="link"
                  className="p-tnano mx-auto"
                  onClick={() => router.push("/signup")}
                >
                  Sign up
                </RhButton>
              </div>
            )}
          {!isPhoneEntered &&
            selectedSignInUserType === USER_ROLE.INTERVIEWER && (
              <div className="flex justify-between flex-col">
                <p className="text-center mt-t2xl">
                  Want to become an interviewer?
                </p>
                <RhButton
                  layout="link"
                  className="p-tnano mx-auto"
                  onClick={() => router.push("/interviewer/apply")}
                >
                  Apply
                </RhButton>
              </div>
            )}
        </>
      )}

      {selectedSignInUserType === USER_ROLE.CLIENT && (
        <>
          <ClientLogin />
        </>
      )}
    </>
  );
};
export default Login;

interface phoneFormPropType {
  handlePhoneSubmit: any;
  phone: PhoneType;
  otpRequestSent: boolean;
}

const PhoneForm = ({
  handlePhoneSubmit,
  phone,
  otpRequestSent,
}: phoneFormPropType) => {
  const validationSchema = Yup.object().shape({
    mobile__form_value: Yup.object().shape({
      dialCode: Yup.string(),
      phoneNumber: Yup.string()
        .typeError("Phone number is invalid.")
        .required("Phone number is required")
        .matches(/^\d+$/, "Phone number is invalid"),
    }),
  });

  const focusInputOnLoadRef = useFocusInputField();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        mobile__form_value: phone,
      }}
      validationSchema={validationSchema}
      onSubmit={handlePhoneSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <>
            <p className="text-base mb-2">Phone Number</p>
            <PhoneFormik
              valueKey={"mobile__form_value"}
              values={values}
              setFormikFieldValue={setFieldValue}
              textInputRef={focusInputOnLoadRef}
            />
            <div className="flex flex-col">
              <RhButton
                type="submit"
                className="w-full mt-t2xl"
                data-testid="request-otp-btn"
              >
                {!otpRequestSent ? "Request OTP" : <RhLoader />}
              </RhButton>
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};

interface OtpFormProps {
  phone: PhoneType;
  handleEditPhone: () => void;
  otp: string;
  setOtp: React.Dispatch<React.SetStateAction<string>>;
  timeLeft: number;
  handleResendOtp: () => void;
  isVerifySent: boolean;
  isRequestingOtp: boolean;
}

const OtpForm = ({
  phone,
  handleEditPhone,
  otp,
  setOtp,
  timeLeft,
  handleResendOtp,
  isVerifySent,
  isRequestingOtp,
}: OtpFormProps) => {
  return (
    <>
      <p className=" mb-tsm text-light">
        OTP sent to {phone.dialCode}
        {phone.phoneNumber}{" "}
        <RhIcon
          onClick={handleEditPhone}
          icon="ri:edit-fill"
          className="cursor-pointer inline ml-tnano"
        />
      </p>

      <div className="flex items-center gap-tnano">
        <RhOTPInput
          length={OTP_LENGTH}
          key={OTP_LENGTH}
          className={`w-[45px] ${
            isVerifySent ? "cursor-not-allowed pointer-events-none" : ""
          }testid-otp-input-field`}
          onChange={(value: string) => setOtp(value)}
          value={otp}
          autoFocus
        />
        {isVerifySent && <RhLoader />}
      </div>
      <RhButton
        className="self-end p-0 mt-3 hover:bg-inherit"
        layout="link"
        disabled={timeLeft !== 0 || isRequestingOtp}
        onClick={handleResendOtp}
        size="sm"
      >
        {timeLeft !== 0 ? `Retry in ${timeLeft}` : "Retry"}
      </RhButton>
    </>
  );
};

export async function getStaticProps() {
  return {
    props: {},
  };
}
